import React, { useEffect, useState } from 'react';
import './Catpage.css';
import Button from './components/Button';
import DropdownComponent from './components/DropdownComponent';
import catimg from './Images/Whiskas-Cat3-1a.png';
import marsLogo from './Images/Mars-Logo.png';
// import { readString } from 'react-papaparse';
// import siteListCSV from './IPv4-Data.csv';
import { setCookie, getCookie, handleRedirection, isMobile } from "./util/util";
import * as Constants from './util/constant';  // Import the constant file
import SplitPane from 'react-split-pane';
import { ColorRing } from 'react-loader-spinner';
import axios from './util/axiosConfig'; // Import the Axios configuration



//Function to find the user's location by passing user ip and the ipv4 csv data as params
// function findCountryByIP(ipaddress, countryInfo) {
//   const matchedArrays = countryInfo.filter((countryArray) => countryArray[0] === ipaddress)
//   console.log("Results---", matchedArrays)
//   const allowedCountryCodes = [Constants.myCountryISO, Constants.phCountryISO, Constants.vnCountryISO, Constants.sgCountryISO, Constants.thCountryISO, Constants.idCountryISO]
//   const filteredArrays = matchedArrays.filter((matchedArray) => allowedCountryCodes.includes(matchedArray[2]));
//   console.log("Check----", filteredArrays)
//   return filteredArrays[0]
//   // return countryInfo.find((element) => element[0].startsWith(ipaddress))
// }

export default function Catpage() {
  const [loading, setLoading] = useState(true);
  // const [ipAddress, setipAddress] = useState("");
  // const [countryData, setCountryData] = useState([]);
  // const [userCountry, setUserCountry] = useState("");
  // const [userISO, setUserISO] = useState("");
  // const [fetchComplete, setFetchComplete] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [geolocationNotFound, setgeolocationNotFound] = useState(false);

  const [usersCountry, setUsersCountry] = useState('');
  const [usersCountryISO, setUsersCountryISO] = useState('');
  const [userCoordinates, setUserCoordinates] = useState(null);

  useEffect(() => {
    // Function to fetch the user's coordinates
    const fetchUserCoordinates = () => {
      if ('geolocation' in navigator) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // const latitude = '14.104530';
            // const longitude = '108.143598'; //Vietnam

            // const latitude = '15.670370';
            // const longitude = '102.707351'; //Thailand

            // const latitude = '12.366322';
            // const longitude = '123.360471'; //Phillipines

            // const latitude = '4.067990';
            // const longitude = '102.324908'; //Malaysia

            // const latitude = '1.364776';
            // const longitude = '103.808443'; //Singapore

            // const latitude = '-2.122927';
            // const longitude = '120.818964'; //Indonesia

            // const latitude = '-6.415403804781487';
            // const longitude = '106.83479159233711'; //Indonesia


            setUserCoordinates({ longitude, latitude });
          }, (error) => {
            console.error(error);
            // switch(error.code){
            //   case 
            // }
            console.error("Geolocation is not supported by your browser.");
            setgeolocationNotFound(true)
          });
        } else {
          console.error("Geolocation is not supported by your browser.");
          setgeolocationNotFound(true)
        }

      }
      else {
        console.error("Geolocation is not supported by your browser.");
        setgeolocationNotFound(true)
      }

    };

    if (getCookie('countryInfo') !== undefined) {
      //If cookie is present after user navigates to product pages, use country info from cookie
      console.log("Cookie is present", getCookie('countryInfo'))
      let countryCookie = JSON.parse(getCookie('countryInfo'))
      let countryNameFromCookie = countryCookie[0]
      let countryISOFromCookie = countryCookie[1]
      setUsersCountry(countryNameFromCookie);
      setUsersCountryISO(countryISOFromCookie);
      console.log("country from cookie----", countryNameFromCookie, countryISOFromCookie)
      console.log("Cookie is present", getCookie('countryInfo'))
      setLoading(false);
    }
    else {
      // Call the function to fetch user coordinates
      // console.log("No cookie present----", usersCountry)
      fetchUserCoordinates();
    }
  }, []);


  // useEffect(() => {
  //   // This useEffect watches for changes in userCoordinates and makes the API request when userCoordinates is updated.
  //   if (userCoordinates) {

  //     const { longitude, latitude } = userCoordinates;
  //     axios.get(`/reverseGeocode?api-version=2023-06-01&coordinates=${longitude},${latitude}&subscription-key=${Constants.geolocationApiKey}`)
  //       .then(response => {
  //         // Parsing the response to get the country data.
  //         const countryname = response.data.features[0].properties.address.countryRegion.name;
  //         const countryISO = response.data.features[0].properties.address.countryRegion.iso;
  //         //console.log("Country-----", country)
  //         checkIfSEACountry(countryname, countryISO)

  //       })
  //       .catch(error => {
  //         console.error(error);
  //       });
  //   }
  // }, [userCoordinates]);

  useEffect(() => {
    // This useEffect watches for changes in userCoordinates and makes the API request when userCoordinates is updated.
    const fetchData = async () => {
      if (userCoordinates) {
        const { longitude, latitude } = userCoordinates;

        try {
          const response = await axios.get(`/reverseGeocode?api-version=2023-06-01&coordinates=${longitude},${latitude}&subscription-key=${Constants.geolocationApiKey}`);
          const countryname = response.data.features[0].properties.address.countryRegion.name;
          const countryISO = response.data.features[0].properties.address.countryRegion.iso;
          //console.log("Country-----", country)
          checkIfSEACountry(countryname, countryISO)
        }
        catch (error) {
          console.error(error)
          setUsersCountry(Constants.phCountryName)
          setUsersCountryISO(Constants.phCountryISO)
          let countryCookieArray = [Constants.phCountryName, Constants.phCountryISO]
          setCookie('countryInfo', countryCookieArray);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [userCoordinates]);

  const checkIfSEACountry = (countryname, countryISO) => {
    console.log("Inside if-------", countryname, countryISO)
    if (countryISO !== undefined && (countryISO === Constants.phCountryISO || countryISO === Constants.myCountryISO || countryISO === Constants.thCountryISO || countryISO === Constants.idCountryISO || countryISO === Constants.vnCountryISO || countryISO === Constants.sgCountryISO)) {
      console.log("Correct------", countryISO)
      setUsersCountry(countryname);
      setUsersCountryISO(countryISO);
      const cookieArray = [countryname, countryISO]
      setCookie('countryInfo', cookieArray);
      setLoading(false);
    }
    else {
      // If user is not from any of 6 places --> set user info to Philippines
      console.log("Wrong----", countryISO)
      setUsersCountry(Constants.phCountryName)
      setUsersCountryISO(Constants.phCountryISO)
      let countryCookieArray = [Constants.phCountryName, Constants.phCountryISO]
      setCookie('countryInfo', countryCookieArray);
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   console.log("Inside if-------", countryname)
  //     if (countryname !== undefined && (countryname === Constants.phCountryISO || countryname === Constants.myCountryISO || countryname === Constants.thCountryISO || countryname === Constants.idCountryISO || countryname === Constants.vnCountryISO || countryname === Constants.sgCountryISO)) {
  //       setUsersCountry(countryname);
  //       setUsersCountryISO(countryISO); 
  //       const cookieArray = [countryname, countryISO]
  //       setCookie('countryInfo', cookieArray);
  //       setLoading(false);
  //     }
  //     {
  //      // If user is not from any of 6 places --> set user info to Philippines
  //           setUsersCountry(Constants.phCountryName)
  //           setUsersCountryISO(Constants.phCountryISO)
  //           let countryCookieArray = [ Constants.phCountryName, Constants.phCountryISO]
  //           setCookie('countryInfo', countryCookieArray);
  //           setLoading(false);
  //     }
  //  }, [countryname, countryISO]);

  // useEffect(() => {
  //   //If country cookie is present, set all country info from cookie
  //   if (getCookie('countryInfo') !== undefined) {
  //     let countryCookie = JSON.parse(getCookie('countryInfo'))
  //     let countryNameFromCookie = countryCookie[1]
  //     let countryISOFromCookie = countryCookie[2]
  //     setUserCountry(countryNameFromCookie);
  //     setUserISO(countryISOFromCookie);
  //     console.log("country from cookie----", countryNameFromCookie, countryISOFromCookie)
  //     console.log("Cookie is present", getCookie('countryInfo'))
  //   }
  //   //If country cookie not present, fetch user's location
  //   else {
  //     console.log("Cookie is not present")
  //     //Fetch user's IP address
  //     fetch('https://api.ipify.org/?format=json')
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log("Check IP address:", data.ip)
  //         setipAddress(data.ip);
  //         setFetchComplete(true);
  //       })
  //       .catch(error => {
  //         console.error(error);
  //       });

  //     //Parse through the ipv4 CSV file
  //     const papaConfig = {
  //       complete: (results, file) => {
  //       setCountryData(results.data)
  //       },
  //       download: true,
  //       error: (error, file) => {
  //         console.log('Error while parsing:', error, file);
  //       },
  //     };
  //     readString(siteListCSV, papaConfig);
  //   }

  // }, []);


  // useEffect(() => {
  //   if (getCookie('countryInfo') !== undefined) {
  //     console.log("Cookie is present", getCookie('countryInfo'))
  //     setLoading(false);
  //   }
  //   else {
  //     if (fetchComplete && countryData.length > 0 && ipAddress !== "") {
  //       // map the user's IP address to format using setIPadressFormat function : "XXX.XXX.XXX." => Exclude last section
  //       let newIpAddress = setIPaddressFormat(ipAddress)
  //       console.log("check ip---", newIpAddress)

  //       // To test
  //       //let newIpAddress = "8.220.128."; //===PH
  //       //let newIpAddress = "1.46.60";   //===TH
  //       //let newIpAddress = "23.195.48."; //===ID
  //       //let newIpAddress = "45.123.96."; //===VN
  //       //let newIpAddress = "14.0.57.";  //===MY
  //       //let newIpAddress = "13.104.111.";
  //       //let newIpAddress = "1.33.0.";
  //       //let newIpAddress = "13.104.184.";


  //       //find user's country array => ['ip addr', 'user country', 'iso code']
  //       let countryArray = findCountryByIP(newIpAddress, countryData)
  //       console.log("Check country----", countryArray)
  //       if (countryArray !== undefined && countryArray.length > 0 && (countryArray[2] === Constants.phCountryISO || countryArray[2] === Constants.myCountryISO || countryArray[2] === Constants.thCountryISO || countryArray[2] === Constants.idCountryISO || countryArray[2] === Constants.vnCountryISO || countryArray[2] === Constants.sgCountryISO)) {
  //         setUserCountry(countryArray[1])
  //         setUserISO(countryArray[2])
  //         console.log("New address----")
  //         setipAddress(newIpAddress)
  //         setCookie('countryInfo', countryArray);
  //         console.log("country-------", countryArray)
  //         setLoading(false);
  //       }
  //       else {
  //         // If user is not from any of 6 places --> set user info to Philippines
  //         setUserCountry(Constants.phCountryName)
  //         setUserISO(Constants.phCountryISO)
  //         let countryCookieArray = ["", Constants.phCountryName, Constants.phCountryISO]
  //         setCookie('countryInfo', countryCookieArray);
  //         setLoading(false);
  //       }
  //     }
  //   }
  // }, [ipAddress, countryData, fetchComplete])

  useEffect(() => {
    console.log("is mobile ------", isMobile())
    setMobile(isMobile());
  }, []);



  const handleClickofWhiskas = () => {
    let url = '';
    console.log('Inside Wiskasssssssss', getCookie('countryInfo'), usersCountry)
    switch (usersCountryISO) {
      case Constants.vnCountryISO:
        //Sheba VN url missing
        url = Constants.whiskas_vn;
        break;
      case Constants.thCountryISO:
        url = Constants.whiskas_th;
        break;
      case Constants.myCountryISO:
        url = Constants.whiskas_my;
        break;
      case Constants.phCountryISO:
        url = Constants.whiskas_ph;
        break;
      case Constants.idCountryISO:
        url = Constants.whiskas_id;
        break;
      case Constants.sgCountryISO:
        url = Constants.whiskas_sg;
        break;
      default: url = Constants.whiskas_ph;
    }

    handleRedirection(url)

  }

  const handleClickofTemptations = () => {
    //console.log("In temp-----", userISO)
    let url = '';
    switch (usersCountryISO) {
      case Constants.vnCountryISO:
        url = Constants.temptations_vn;
        break;
      case Constants.thCountryISO:
        url = Constants.temptations_th;
        break;
      case Constants.myCountryISO:
        url = Constants.temptations_my;
        break;
      case Constants.phCountryISO:
        url = Constants.temptations_ph;
        break;
      case Constants.idCountryISO:
        url = Constants.temptations_id;
        break;
      case Constants.sgCountryISO:
        url = Constants.temptations_sg;
        break;
      default: url = Constants.temptations_ph;
    }

    handleRedirection(url)

  }

  const handleClickofSheba = () => {
    //console.log("In temp-----", userISO)
    let url = '';
    switch (usersCountryISO) {
      case Constants.vnCountryISO:
        //Sheba VN url missing
        url = Constants.sheba_vn;
        break;
      case Constants.thCountryISO:
        url = Constants.sheba_th;
        break;
      case Constants.myCountryISO:
        url = Constants.sheba_my;
        break;
      case Constants.phCountryISO:
        url = Constants.sheba_ph;
        break;
      case Constants.idCountryISO:
        url = Constants.sheba_id;
        break;
      case Constants.sgCountryISO:
        url = Constants.sheba_sg;
        break;
      default: url = Constants.sheba_ph;
    }

    handleRedirection(url)

  }

  const handleClickofIAMS = () => {
    let url = '';
    switch (usersCountryISO) {
      case Constants.vnCountryISO:
        //Sheba VN url missing
        url = Constants.iams_vn;
        break;
      case Constants.thCountryISO:
        url = Constants.iams_th;
        break;
      case Constants.myCountryISO:
        url = Constants.iams_my;
        break;
      case Constants.phCountryISO:
        url = Constants.iams_ph;
        break;
      case Constants.idCountryISO:
        url = Constants.iams_id;
        break;
      case Constants.sgCountryISO:
        url = Constants.iams_sg;
        break;
      default: url = Constants.iams_ph;
    }
    
    handleRedirection(url)

  }


  const handleCountryChange = (country, iso) => {
    console.log("In handle country---------", country, iso)
    setUsersCountry(country)
    setUsersCountryISO(iso)
    let countryCookieArray = [country, iso]
    setCookie('countryInfo', countryCookieArray);
  }




  // -------Render--------

  return (
    <div>
      {
        //--------------Mobile---------------------------  
        mobile ?
        (
          <div className='container_mob'>
            <div className='top'>
              <img src={marsLogo} className='image1' alt="LogoMars" />
              <img src={catimg} className='image2' alt="whiskas_cat" />
            </div>

            <div className='bottom'>
              {geolocationNotFound ? (
                <div className='div-50'>
                  <div style={{ display: 'block', justifyContent: 'center', alignItems: 'center', padding: '15%' }}>
                    <span style={{ color: "white", fontSize: '15px' }}>Geolocation is disabled on your browser. To view our products enable site permissions for location and reload screen.</span>
                  </div>
                </div>
              )
                :
                (
                  <div className='div-50'>
                    {loading ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <ColorRing
                          visible={true}
                          height="60"
                          width="60"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#346f9f', '#346f9f', '#346f9f', '#346f9f', '#346f9f']}
                        />
                      </div>
                    )
                      : (
                        <div>
                          <div className='textprod'>
                            <span className='textprodText'>View products</span>
                          </div>
                          <div className="buttonDivMobile" >
                            <Button onClick={handleClickofWhiskas} className="buttonMob12 button1" centerImage={true} labelText={false} imageStyle="image-class" imageURL={require('./Images/whiskas_logo.png')} imageAlt='whiskas_logo' />
                            <Button onClick={handleClickofTemptations} className="buttonMob12 button2" centerImage={true} labelText={false} imageStyle="image-class" imageURL={require('./Images/temptations_logo.png')} imageAlt='temptations_logo' />
                            <Button onClick={handleClickofSheba} className="buttonMob34 button3" centerImage={true} labelText={false} imageStyle="image-class" imageURL={require('./Images/sheba.png')} imageAlt='sheba_logo' />
                            <Button onClick={handleClickofIAMS} className="buttonMob34 button4" centerImage={true} labelText={false} imageStyle="image-class" imageURL={require('./Images/iams_logo.png')} imageAlt='iams_logo' />
                          </div>


                          <div className='footer-wrapper'>
                            <div className='dropdown'>
                              <span style={{ marginRight: '5px', color: "white" }}>Region</span>
                              <DropdownComponent initialValue={usersCountry} onCountryChange={handleCountryChange} />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                )}

            </div>
          </div>
          )
          //--------------Desktop--------------------------
          :
          (
            <SplitPane split="horizontal" defaultSize={"50%"} >
              <SplitPane split="vertical" defaultSize={"50%"} style={{ zIndex: "999", bottom: "-8%" }}>
                <img src={marsLogo} className='logoImgpick' alt="LogoMars" />
                <img src={catimg} className='catImgStyle' alt="whiskas_cat" />
              </SplitPane>

              {geolocationNotFound ? (
                <div className='div-50'>
                  <div style={{ display: 'block', justifyContent: 'center', alignItems: 'center', padding: '5%' }}>
                    <p style={{ color: "white", fontSize: '15px' }}>Geolocation is disabled on your browser. </p>
                    <p style={{ color: "white", fontSize: '15px' }}>To view our products enable site permissions for location and reload screen.</p>
                  </div>
                </div>
              )
                :
                (

                  <div className='div-50'>
                    {loading ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <ColorRing
                          visible={true}
                          height="60"
                          width="60"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#346f9f', '#346f9f', '#346f9f', '#346f9f', '#346f9f']}
                        />
                      </div>
                    )
                      : (
                        <div>
                          <div className='textprod'>
                            <span style={{ color: "white", marginTop: "10px" }}>View products</span>
                            <div className="buttonDiv" >
                              <Button onClick={handleClickofWhiskas} className="button button1" centerImage={true} labelText={false} imageStyle="image-class" imageURL={require('./Images/whiskas_logo.png')} imageAlt='whiskas_logo' />
                              <Button onClick={handleClickofTemptations} className="button button2" centerImage={true} labelText={false} imageStyle="image-class" imageURL={require('./Images/temptations_logo.png')} imageAlt='temptations_logo' />
                              <Button onClick={handleClickofSheba} className="button button3" centerImage={true} labelText={false} imageStyle="image-class" imageURL={require('./Images/sheba.png')} imageAlt='sheba_logo' />
                              <Button onClick={handleClickofIAMS} className="button button4" centerImage={true} labelText={false} imageStyle="image-class" imageURL={require('./Images/iams_logo.png')} imageAlt='iams_logo' />
                            </div>
                          </div>

                          <div className='footer-wrapper'>
                            <div className='dropdown'>
                              <span style={{ marginRight: '5px', color: "white" }}>Region</span>
                              <DropdownComponent initialValue={usersCountry} onCountryChange={handleCountryChange} />
                            </div>
                          </div>
                        </div>
                      )}

                  </div>
                )}

            </SplitPane>
          )
      }
    </div>

  );
}

